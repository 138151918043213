import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'dol-footer-bar',
  templateUrl: './footer-bar.component.html',
  styleUrls: ['./footer-bar.component.css']
})
export class FooterBarComponent implements OnInit {
  year: string;
  showCookieModal: boolean = false;

  columns = [
    { field: 'name', header: 'Name of cookie' },
    { field: 'description', header: 'Description' },
    { field: 'userexperience', header: 'User Experience or Website Functionality' },
    { field: 'storageperiod', header: 'Storage Period (if applicable)' }
  ]

  cookieData = [
    {
      name: 'incap_ses_*<br><br>visid_incap_*',
      description: 'Used to improve the security of the website.',
      userexperience: 'Website Functionality',
      storageperiod: 'Expires after 2 years'
    },
    {
      name: 'nlbi_*',
      description: 'Used to ensure requests by a client are sent to the same origin server',
      userexperience: 'Website Functionality',
      storageperiod: 'Session'
    },
    {
      name: 'LSESSIONID',
      description: 'Used to record the user session.',
      userexperience: 'Website Functionality',
      storageperiod: 'Session'
    },
    {
      name: '_ga*<br><br>_utm<br><br>',
      description: 'Used to keep a record of visitor statistics.',
      userexperience: 'Website Functionality',
      storageperiod: '2 years'
    },
    {
      name: '_gid',
      description: 'Used to keep a record of visitor statistics.',
      userexperience: 'Website Functionality',
      storageperiod: '24 hours'
    },
    {
      name: '_gat',
      description: 'Used to throttle request rate.',
      userexperience: 'Website Functionality',
      storageperiod: '1 minute'
    },
    {
      name: '__so',
      description: 'Used to record the user session.',
      userexperience: 'Website Functionality',
      storageperiod: 'Session'
    }
  ];

  ngOnInit(): void {
    this.year = new Date().getFullYear().toString();
  }
}